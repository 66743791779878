.loading {
  width: 96px;
  text-align: center;
  font-size: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -48px;
  margin-left: -48px;

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.default-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;

  .btn {
    width: 95px;
    height: 30px;
    background: linear-gradient(48deg, rgba(255, 182, 25, 1), rgba(255, 207, 88, 1));
    box-shadow: 0px 6px 12px 0px rgba(255, 213, 15, 0.23);
    opacity: 0.94;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-weight: 600;
  }
}