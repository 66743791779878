// :global(*) {
//     margin: 0;
//     padding: 0;
// }
// :global(.routerChangeContainer) {
//     position: relative;
// }
:global(.routerComponentContainer) {
  width: 100vw;
  height: 100%;
  background-color: white;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(.36, .66, .04, 1);
  transition-timing-function: cubic-bezier(.36, .66, .04, 1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform
}

// :global(.routeChange-back-leave) {
//     opacity: 0;
//     -webkit-transform: translate3D(0,0,0);
//     -moz-transform: translate3D(0,0,0);
//     -ms-transform: translate3D(0,0,0);
//     -o-transform: translate3D(0,0,0);
//     transform: translate3D(0,0,0);
//     position: fixed;
//     width : 100%;
//     top:0;
//     left:0;
//     z-index: 1000;
// }
// :global(.routeChange-back-leave.routeChange-back-leave-active) {
//     opacity: 0;
//     -webkit-transform: translate3D(-100%,0,0);
//     -moz-transform: translate3D(-100%,0,0);
//     -ms-transform: translate3D(-100%,0,0);
//     -o-transform: translate3D(-100%,0,0);
//     transform: translate3D(-100%,0,0);

//     -webkit-transition: transform 300ms ease-in-out;
//     -moz-transition: transform 300ms ease-in-out;
//     -ms-transition: transform 300ms ease-in-out;
//     -o-transition: transform 300ms ease-in-out;
//     transition: transform 300ms ease-in-out;
// }
// :global(.routeChange-back-enter) {
//     -webkit-transform: translate3D(-100%,0,0);
//     -moz-transform: translate3D(-100%,0,0);
//     -ms-transform: translate3D(-100%,0,0);
//     -o-transform: translate3D(-100%,0,0);
//     transform: translate3D(-100%,0,0);
//     position: fixed;
//     width : 100%;
//     top:0;
//     left:0;
//     z-index: 10000;
// }
// :global(.routeChange-back-enter.routeChange-back-enter-active) {
//     opacity: 1;
//     -webkit-transform: translate3D(0,0,0);
//     -moz-transform:translate3D(0,0,0);
//     -ms-transform: translate3D(0,0,0);
//     -o-transform:translate3D(0,0,0);
//     transform:translate3D(0,0,0);

//     -webkit-transition: transform 300ms ease-in-out;
//     -moz-transition: transform 300ms ease-in-out;
//     -ms-transition: transform 300ms ease-in-out;
//     -o-transition: transform 300ms ease-in-out;
//     transition: transform 300ms ease-in-out;

// }

// :global(.routeChange-forward-enter) {
//     -webkit-transform: translate3D(100%,0,0);
//     -moz-transform: translate3D(100%,0,0);
//     -ms-transform:translate3D(100%,0,0);
//     -o-transform: translate3D(100%,0,0);
//     transform: translate3D(100%,0,0);
//     position: fixed;
//     width : 100%;
//     top:0;
//     left:0;
//     z-index: 10000;
// }
// :global(.routeChange-forward-enter.routeChange-forward-enter-active) {
//     -webkit-transform: translate3D(0,0,0);
//     -moz-transform: translate3D(0,0,0);
//     -ms-transform: translate3D(0,0,0);
//     -o-transform: translate3D(0,0,0);
//     transform: translate3D(0,0,0);

//     -webkit-transition: transform 300ms ease-in-out;
//     -moz-transition: transform 300ms ease-in-out;
//     -ms-transition: transform 300ms ease-in-out;
//     -o-transition: transform 300ms ease-in-out;
//     transition: transform 300ms ease-in-out;
// }
// :global(.routeChange-forward-leave.routeChange-forward-leave-active) {
//     opacity: 0;
//     -webkit-transform: translate3D(100%,0,0);
//     -moz-transform: translate3D(100%,0,0);
//     -ms-transform: translate3D(100%,0,0);
//     -o-transform: translate3D(100%,0,0);
//     transform: translate3D(100%,0,0);

//     -webkit-transition: transform 300ms ease-in-out;
//     -moz-transition: transform 300ms ease-in-out;
//     -ms-transition: transform 300ms ease-in-out;
//     -o-transition: transform 300ms ease-in-out;
//     transition: transform 300ms ease-in-out;
// }

// launcher动画
.fade-in-leave{
  position: absolute;
  left: 0;
  top: 0;
  opacity: .8;
  z-index: 1;
  transform: translate3D(0, 0, 0);
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-timing-function: cubic-bezier(.36, .66, .04, 1);
  transition-timing-function: cubic-bezier(.36, .66, .04, 1);
  &.fade-in-leave-active{
    opacity: 0;
    transition-duration: .2s;
  }
}

// 进出场动画
// 飞入
//cubic-bezier(0.2, 0.15, 0.85, 1.25)回弹效果
.slide-in-appear {
  -webkit-transform: translate3D(100%, 0, 0);
  transform: translate3D(100%, 0, 0);
  opacity: 1;
  z-index: 2;

  &.slide-in-appear-active {
    transform: translate3D(0, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
}

.slide-in-enter {
  -webkit-transform: translate3D(100%, 0, 0);
  transform: translate3D(100%, 0, 0);
  opacity: 1;
  z-index: 2;

  &.slide-in-enter-active {
    transform: translateZ(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15)
  }
}

// 向左滑出
.slide-in-leave {
  position: absolute;
  left: 0;
  top: 0;
  opacity: .8;
  z-index: 1;
  transform: translate3D(0, 0, 0);
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-timing-function: cubic-bezier(.36, .66, .04, 1);
  transition-timing-function: cubic-bezier(.36, .66, .04, 1);

  &.slide-in-leave-active {
    // opacity: 0.01;
    -webkit-transform: translate3D(-33%, 0, 0);
    transform: translate3D(-33%, 0, 0);
    opacity: 0;
  }
}

/////////////////////////////////////
.slide-in-back-enter {
  -webkit-transform: translate3D(-33%, 0, 0);
  transform: translate3D(-33%, 0, 0);
  opacity: .8;
  z-index: 1;

  &.slide-in-back-enter-active {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
    z-index: 1;
  }
}

// 向左滑出
.slide-in-back-leave {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
  z-index: 2;

  &.slide-in-back-leave-active {
    // opacity: 0.01;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    -webkit-transform: translate3D(100%, 0, 0);
    transform: translate3D(100%, 0, 0);
    opacity: 1;
    z-index: 2
  }
}
